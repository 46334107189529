<template>
  <div>
    <div class="flexRow justify-content-between">
      <div class="filterBox">
<!--        <div class="filterCell">
          <span>项目名称</span>
          <el-input v-model="searchData.proName" placeholder="请输入关键词" clearable>
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="filterCell">
          <span>时间</span>
          <el-date-picker
              v-model="searchData.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>-->
      </div>
      <div>
        <el-button type="primary" @click="openBox(1)">新建项目</el-button>
      </div>
    </div>
    <div class="marginTop">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="num" label="序号" align="center" width="80"></el-table-column>
        <el-table-column prop="name" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="desc" label="项目描述" align="center"></el-table-column>
        <el-table-column prop="st" label="开始时间" align="center"></el-table-column>
        <el-table-column prop="et" label="结束时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <span class="editBtn" @click="openBox(2,scope.$index)">编辑</span>
            <span class="editBtn" @click="delItem(scope.row.id)">删除</span>
            <el-button type="primary" size="mini" @click="toLog(scope.row.id)">查看日志</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page flexRow justify-content-center">
        <el-pagination
            background
            layout="total,prev, pager, next,jumper"
            :current-page="curPage"
            :total="totalItem"
            :page-size="20"
            @current-change="toPages">
        </el-pagination>
      </div>
    </div>
    <!--  新建项目  -->
    <el-dialog :visible.sync="showBox" width="30%">
      <el-form ref="form" label-width="90px">
        <el-form-item required label="项目名称">
          <el-input v-model="formData.name" placeholder="请输入项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker
              v-model="formData.start_time"
              type="date"
              style="width:100%"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
              v-model="formData.end_time"
              type="date"
              style="width:100%"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input type="textarea" v-model="formData.desc" placeholder="请输入项目描述" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="marginTop flexRow justify-content-end">
        <el-button type="primary" @click="subMsg">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "ProjectList",
  data(){
    return{
      searchData:{
        proName:'',
        date:''
      },
      tableData:[],
      totalItem:0,
      curPage:1,
      showBox:false,
      formData:{
        name:'',
        start_time:'',
        end_time:'',
        desc:'',
      }
    }
  },
  created(){
    this.$setMenuActive('/')
  },
  mounted(){
    this.p = 1;
    this.getList({p:1});
  },
  methods:{
    //组装参数
    saveParams(name,date,p){
      let params = {p};
      if(name){
        params.name = name;
      }
      if(date){
        let st = (new Date(date[0]).getTime()) / 1000;
        let et = (new Date(date[1]).getTime()) / 1000;
        et += 86399;
        params.time = [st,et];
      }
      return params;
    },
    //清除信息
    clearInfo(){
      this.formData = {
        name:'',
        start_time:'',
        end_time:'',
        desc:'',
      }
    },
    //获取列表
    async getList(params){
      let res = await this.mainFun('Project/getList',params);
      this.totalItem = res.data.total;
      let data = res.data.data;
      data.map((item,i)=>{
        let num = (res.data.current_page - 1) * res.data.per_page + parseInt(i) + 1;
        item.num = num;
        item.ct = this.$time(item.create_time)
        item.ut = this.$time(item.update_time)
        item.st = this.$time(item.start_time,1)
        item.et = this.$time(item.end_time,1)
        return item
      })
      this.tableData = data;
    },
    //分页
    toPages(p){
      this.p = p;
      this.curPage = p;
      this.getList(p)
    },
    //打开新建or编辑弹窗
    openBox(type,index){
      if(type === 1){
        this.eid = '';
        this.clearInfo()
      }else{
        let item = this.tableData[index];
        this.eid = item.id;
        this.formData = {
          name:item.name,
          start_time:item.st,
          end_time:item.et,
          desc:item.desc,
        }
      }
      this.showBox = true;
    },
    //提交信息
    async subMsg(){
      if(this.formData.name === ''){
        this.$message.warning('请输入项目名称');
        return;
      }
      let {name,start_time,end_time,desc} = this.formData;
      let st = new Date(start_time).getTime() / 1000;
      let et = new Date(end_time).getTime() / 1000;
      let params = {
        name,
        desc,
        start_time:st,
        end_time:et
      }
      let url = 'Project/'
      if(this.eid){
        params.id = this.eid;
        url += 'edit'
      }else{
        url += 'add'
      }
      let res = await this.mainFun(url,params);
      if(res.data.code == 1){
        this.$message.success(res.data.message);
        let p = 1;
        if(params.id){
          p = this.p;
        }
        let {proName,date} = this.searchData
        let newParams = this.saveParams(proName,date,p)
        await this.getList(newParams)
        this.showBox = false;
      }else{
        this.$message.error(res.data.message);
      }
    },
    //删除当前信息
    delItem(id){
      this.$confirm('是否确认删除当前信息','提示',{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
        type:'warning',
      }).then(async ()=>{
        let res = await this.mainFun('Project/del',{id});
        if(res.data.code === 1){
          this.$message.success(res.data.message);
          let {proName,date} = this.searchData
          let newParams = this.saveParams(proName,date,this.p)
          await this.getList(newParams)
        }
      }).catch(()=>{})
    },
    //点击查看日志
    toLog(id){
      this.$router.push({
        path:'/projectLog',
        query:{id}
      })
    },
  }
}
</script>

<style scoped>

</style>